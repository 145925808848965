<route>
{
  "meta": {
    "permission": [
      "packages.view_package"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="
              package_o.id
                ? `${$t('edit')} ${$tc('package', 1)}`
                : `${$t('add', { value: $tc('package', 1) })}`
            "
            :loading="load"
          ></i-toolbar>
          <v-card-text>
            <v-row class="py-5">
              <v-col cols="12" sm="6" md="3">
                <ValidationProvider
                  vid="name"
                  :name="$tc('name', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="package_o.name"
                    :label="$tc('name', 2)"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <ValidationProvider
                  vid="days"
                  :name="$tc('days', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="package_o.days"
                    :label="$tc('days', 2)"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <ValidationProvider
                  vid="expiration_days"
                  :name="$tc('expiration_days', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="package_o.expiration_days"
                    :label="$tc('expiration_days', 2)"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <ValidationProvider
                  vid="cost"
                  :name="$tc('cost', 1)"
                  rules="required|double:2"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="package_o.cost"
                    :label="$tc('cost', 2)"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <ValidationProvider
                  vid="cost_prom"
                  :name="$tc('cost_prom', 1)"
                  rules="double:2"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="package_o.cost_prom"
                    :label="$tc('cost_prom', 2)"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <ValidationProvider
                  vid="cost_lat"
                  :name="$tc('cost_lat', 1)"
                  rules="required|double:2"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="package_o.cost_lat"
                    :label="$tc('cost_lat', 2)"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <ValidationProvider
                  vid="cost_lat_prom"
                  :name="$tc('cost_lat_prom', 1)"
                  rules="double:2"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="package_o.cost_lat_prom"
                    :label="$tc('cost_lat_prom', 2)"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-switch class="v-switch mt-2" name="hidden" v-model="hidden">
                  <template v-slot:label>
                    {{ $t('hidden') }}
                    <v-icon v-if="hidden" color="light-green" class="pl-3">
                      fa-check
                    </v-icon>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="12">
                <label for="description">{{ $t('description') }}</label>
                <vue-editor
                  id="body"
                  :editorToolbar="customToolbar"
                  v-model="package_o.description"
                >
                </vue-editor>
              </v-col>
              <v-col cols="12">
                <label for="description_lat">
                  {{ $t('description_lat') }}
                </label>
                <vue-editor
                  id="body"
                  :editorToolbar="customToolbar"
                  v-model="package_o.description_lat"
                >
                </vue-editor>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { VueEditor } from 'vue2-editor'
export default {
  components: {
    VueEditor
  },
  data() {
    return {
      dialog: false,
      package_o: {
        name: '',
        days: 0,
        expiration_days: 0,
        cost: 0,
        cost_prom: 0,
        cost_lat: 0,
        cost_lat_prom: 0,
        description: '',
        description_lat: ''
      },
      hidden: false,
      edit: false,
      id: 0,
      load: false,
      customToolbar: [
        [{ header: [] }],
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code',
          'code-block'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }]
      ]
    }
  },
  methods: {
    async submit() {
      try {
        this.load = true
        this.package_o.id === '' || this.package_o.id === undefined
          ? await this.$api.subDomain.packages.create({
              form: this.package_o
            })
          : await this.$api.subDomain.packages.edit({
              pk: this.package_o.id,
              form: this.package_o
            })
        this.$toast.success(
          `${this.$tc('package', 1)} ${this.$tc(
            this.package_o.id !== undefined ? 'edited' : 'created',
            2
          )}`
        )
        this.$router.push({ name: 'package' })
      } finally {
        this.load = false
      }
    },
    getPackages(item, id) {
      this.loading = true
      let validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (validation == 'create') {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
          this.$route.meta.title = this.$t('create', { model: '' })
        }
        this.package_o = {
          name: '',
          days: 0,
          expiration_days: 0,
          cost: 0,
          cost_prom: 0,
          cost_lat: 0,
          cost_lat_prom: 0,
          description: '',
          description_lat: ''
        }
      } else {
        this.$api.subDomain.packages
          .show({ pk: validation })
          .then((res) => {
            this.package_o = res.data
            this.$route.meta.title = this.$t('edit', { model: '' })
          })
          .catch(() => this.$router.post({ name: 'package' }))
      }
    }
  },
  mounted() {
    this.getPackages()
  }
}
</script>
